import styled from 'styled-components'
import React, {useEffect} from 'react'

export const BREAKPOINT = '768px'
export const SIDE_PADDING = '8.33vw'

export const Spacer = styled.div`
  height: ${props => props.height / 14.40}vw;
  flex-shrink: 0;
  
  @media (max-width: ${BREAKPOINT}) {
    height: ${props => (props.mobileHeight === undefined ? props.height : props.mobileHeight) / 3.75}vw;
  }
`

export const Button = styled.div`
  display: inline-flex;
  padding: 0 20px;
  align-items: center;
  height: 40px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
`

export const CTAButton = styled(Button)`
  height: 56px;
  background-color: #017cee;
  color: #ffffff;
`

export const Text1 = styled.div`
  font-family: 'Gibson-SemiBold', sans-serif;
  font-size: 64px;
  line-height: 72px;
  
  @media (max-width: ${BREAKPOINT}) {
    font-size: 32px;
    line-height: 48px;
  }
`

export const Text2 = styled.div`
  font-family: 'Gibson-SemiBold', sans-serif;
  font-size: 36px;
  line-height: 48px;
  
  @media (max-width: ${BREAKPOINT}) {
    font-size: 24px;
    line-height: 36px;
  }
`

const Text3 = styled.div`
  font-family: 'Gibson-SemiBold', sans-serif;
  font-size: 32px;
  line-height: 72px;
`

export const Text3Light = styled(Text3)`
  color: rgba(255, 255, 255, 0.5);
`

export const Text3Dark = styled(Text3)`
  color: rgba(0, 0, 0, 0.5);
`

export const Text4 = styled.div`
  font-size: 24px;
  line-height: 38px;
  color: #828282;
  
  @media (max-width: ${BREAKPOINT}) {
    font-size: 14px;
    line-height: 26px;
  }
`

export const Text5 = styled.div`
  font-size: 20px;
  line-height: 38px;
  letter-spacing: -0.25px;
  
  @media (max-width: ${BREAKPOINT}) {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: inherit;
  }
`

export const TextStat = styled.div`
font-family: 'LotaGrotesque-Thin';
font-size: 96px;
line-height: 96px;
font-weight: 300;

@media (max-width: ${BREAKPOINT}) {
  font-size: 64px;
  line-height: 83px;
}
`

export const TextStatLabel = styled.div`
font-size: 24px;
line-height: 24px;

@media (max-width: ${BREAKPOINT}) {
  font-size: 16px;
}
`

export const StatContainer = styled.div`
height: 226px;
width: 268px;
display: flex;
flex-direction: column;
justify-content: flex-end;
border-left: 1px solid #017CEE;
padding-left: 32px;
margin-bottom: 38px;

@media (max-width: ${BREAKPOINT}) {
  height: 160px;
  margin-bottom: 27px;
}
`

export function InternalLink({href, children}) {
  return (
    <LinkContainer>
      <a href={href}>{children}</a>
    </LinkContainer>
  )
}

export function ExternalLink({href, children}) {
  return (
    <LinkContainer>
      <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
    </LinkContainer>
  )
}

const LinkContainer = styled.span`
  a {
    color: inherit;
    text-decoration: none;
  }
`

const CALENDLY_URL = 'https://calendly.com/c-juarez/15min'

export function Schedule({children, uniqueID}) {
  if (!global.Calendly) {
    return <ScheduleContainer/>
  }
  const handleClick = () => {
      global.Calendly.initPopupWidget({
        url: CALENDLY_URL,
        parentElement: document.getElementById(uniqueID),
      })
  }
  return (
    <ScheduleContainer>
      <button id={uniqueID} onClick={handleClick}>
        {children}
      </button>
    </ScheduleContainer>
  )
}

const ScheduleContainer = styled.div`
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    outline: none;
  }
`

export function InlineSchedule({children, uniqueID}) {
  useEffect(() => {
    if (global.Calendly) {
      global.Calendly.initInlineWidget({
        url: CALENDLY_URL,
        parentElement: document.getElementById(uniqueID),
      })
    }
    return () => {
      document.getElementById(uniqueID).innerHTML = ''
    }
  }, [uniqueID])
  return (
    <InlineScheduleContainer id={uniqueID}/>
  )
}

const InlineScheduleContainer = styled.div`
  height: 1140px;
`

